<template>
  <div ref="viewport" className="viewport" style="width:100%;height: 100%;">
    <div class="pop-up" v-if="isShow">
      <div class="msg">
        <svg viewBox="25 25 50 50" class="circular" v-if="infoIcon">
          <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
        </svg>
        <h2 style="color: #fff;">{{ infoText }}</h2>
      </div>
    </div>
    <!--      <img :src="logoImg" class="logo" alt="logo">-->
    <modal ref="modal" @reconnect="connect()"/>
    <!-- tabindex allows for div to be focused -->
    <div ref="display" className="display" tabIndex="0"/>
  </div>
</template>

<script>
import Guacamole from 'guacamole-common-js'
import GuacMouse from '@src/lib/GuacMouse'
import clipboard from '@src/lib/clipboard'
import states from '@src/lib/states'
import Modal from '@views/pages/components/Modal'

Guacamole.Mouse = GuacMouse.mouse

const httpUrl = ``

export default {
  components: {
    Modal
  },
  props: {
    forceHttp: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isShow: true,
      infoIcon: true,
      infoText: '',
      port: 0,
      query: {
        type: 'rdp',
        required: true
      },
      logoImg: require('@assets/images/logo.svg'),
      connected: false,
      display: null,
      currentAdjustedHeight: null,
      client: null,
      keyboard: null,
      mouse: null,
      lastEvent: null,
      connectionState: states.IDLE,
      errorMessage: '',
      arguments: {},
      vm: [],
      autoTimer: null
    }
  },
  watch: {
    connectionState(state) {
      this.$refs.modal.show(state, this.errorMessage)
    }
  },
  methods: {
    send(cmd) {
      if (!this.client) {
        return
      }
      for (const c of cmd.data) {
        this.client.sendKeyEvent(1, c.charCodeAt(0))
      }
    },
    handleMouseState(mouseState) {
      const scaledMouseState = Object.assign({}, mouseState, {
        x: mouseState.x / this.display.getScale(),
        y: mouseState.y / this.display.getScale(),
      })
      this.client.sendMouseState(scaledMouseState);
    },
    resize() {
      const elm = this.$refs.viewport

      if (!elm || !elm.offsetWidth) {
        // resize is being called on the hidden window
        return
      }

      let pixelDensity = window.devicePixelRatio || 1
      const width = elm.clientWidth * pixelDensity
      const height = elm.clientHeight * pixelDensity
      if (this.display.getWidth() !== width || this.display.getHeight() !== height) {
        this.client.sendSize(width, height)
      }
      // setting timeout so display has time to get the correct size
      setTimeout(() => {
        const scale = Math.min(
            elm.clientWidth / Math.max(this.display.getWidth(), 1),
            elm.clientHeight / Math.max(this.display.getHeight(), 1)
        )
        this.display.scale(scale)
      }, 100)
    },
    connect() {
      let tunnel

      const ws = document.location.protocol == 'http:' ? 'ws:' : 'wss:';

      if (window.WebSocket && !this.forceHttp) {
        tunnel = new Guacamole.WebSocketTunnel(ws + "//" + this.vm.guacamole_link + "?height=" + (document.documentElement.clientHeight) + "&width=" + (document.documentElement.clientWidth) + "&port=" + this.port + "&")
      } else {
        tunnel = new Guacamole.HTTPTunnel(httpUrl, true)
      }

      if (this.client) {
        this.display.scale(0)
        this.uninstallKeyboard()
      }

      this.client = new Guacamole.Client(tunnel);
      clipboard.install(this.client)

      tunnel.onerror = status => {
        // eslint-disable-next-line no-console
        console.error(`Tunnel failed ${JSON.stringify(status)}`)
        this.connectionState = states.TUNNEL_ERROR
      }

      tunnel.onstatechange = state => {
        switch (state) {
            // Connection is being established
          case Guacamole.Tunnel.State.CONNECTING:
            this.connectionState = states.CONNECTING
            break;

            // Connection is established / no longer unstable
          case Guacamole.Tunnel.State.OPEN:
            this.connectionState = states.CONNECTED
            break;

            // Connection is established but misbehaving
          case Guacamole.Tunnel.State.UNSTABLE:
            // TODO
            break;

            // Connection has closed
          case Guacamole.Tunnel.State.CLOSED:
            this.connectionState = states.DISCONNECTED;
            this.isShow = true
            this.infoIcon = false
            this.infoText = "连接已关闭"
            this.$message({
              message: '连接已关闭',
              type: 'error'
            })
            break;
        }
      }

      this.client.onstatechange = clientState => {
        switch (clientState) {
          case 0:
            this.connectionState = states.IDLE
            break
          case 1:
            // connecting ignored for some reason?
            break
          case 2:
            this.connectionState = states.WAITING
            break
          case 3:
            this.connectionState = states.CONNECTED
            window.addEventListener('resize', this.resize)
            this.$refs.viewport.addEventListener('mouseenter', this.resize)
            this.isShow = false
            clipboard.setRemoteClipboard(this.client)

            // eslint-disable-next-line no-fallthrough
          case 4:
          case 5:
            // disconnected, disconnecting
            break
        }
      }

      this.client.onerror = error => {
        this.client.disconnect()
        // eslint-disable-next-line no-console
        console.error(`Client error ${JSON.stringify(error)}`)
        this.errorMessage = error.message
        this.connectionState = states.CLIENT_ERROR
      }

      this.client.onsync = () => {
      }

      // Test for argument mutability whenever an argument value is received
      this.client.onargv = (stream, mimetype, name) => {
        if (mimetype !== 'text/plain')
          return;

        const reader = new Guacamole.StringReader(stream);

        // Assemble received data into a single string
        let value = '';
        reader.ontext = text => {
          value += text;
        };

        // Test mutability once stream is finished, storing the current value for the argument only if it is mutable
        reader.onend = () => {
          const stream = this.client.createArgumentValueStream('text/plain', name)
          stream.onack = status => {
            if (status.isError()) {
              // ignore reject
              return
            }
            this.arguments[name] = value
          }
        };
      }

      this.client.onclipboard = clipboard.onClipboard
      this.display = this.client.getDisplay()
      const displayElm = this.$refs.display
      displayElm.appendChild(this.display.getElement());
      displayElm.addEventListener('contextmenu', e => {
        e.stopPropagation();
        if (e.preventDefault) {
          e.preventDefault();
        }
        e.returnValue = false;
      })
      this.client.connect();
      window.onunload = () => this.client.disconnect();

      this.mouse = new Guacamole.Mouse(displayElm);
      // Hide software cursor when mouse leaves display
      this.mouse.onmouseout = () => {
        if (!this.display) return;
        this.display.showCursor(false);
      }

      // allows focusing on the display div so that keyboard doesn't always go to session
      displayElm.onclick = () => {
        displayElm.focus()
      }
      displayElm.onfocus = () => {
        displayElm.className = 'focus'
      }
      displayElm.onblur = () => {
        displayElm.className = ''
      }

      this.keyboard = new Guacamole.Keyboard(displayElm);
      this.installKeyboard()
      this.mouse.onmousedown = this.mouse.onmouseup = this.mouse.onmousemove = this.handleMouseState
      setTimeout(() => {
        this.resize()
        displayElm.focus()
      }, 1000); // $nextTick wasn't enough
    },
    installKeyboard() {
      this.keyboard.onkeydown = keysym => {
        this.client.sendKeyEvent(1, keysym);
      };
      this.keyboard.onkeyup = keysym => {
        this.client.sendKeyEvent(0, keysym);
      };
    },
    uninstallKeyboard() {
      this.keyboard.onkeydown = this.keyboard.onkeyup = () => {
      }
    },
    getPort() {

      // 获取端口

      this.$axios.get('/VM/CheckRDP?uuid=' + this.$route.query.id).then((res) => {
            // if (res.data.data === null) {
            //   this.$message({
            //     message: res.data.msg + '，请稍后尝试刷新页面',
            //     type: 'warning'
            //   })
            // }


            if (res.data.msg === "环境未启动") {
              clearInterval(this.autoTimer);
              this.autoTimer = null
              this.isShow = true
              this.infoIcon = false
              this.infoText = "环境不存在"
              return false
            }

            if (res.data.data !== null) {

              clearInterval(this.autoTimer);
              this.autoTimer = null


              this.port = res.data.data
              this.$axios.post('/VM/GetUserEnv?envid=' + this.$route.query.id).then((res) => {
                    if (!res.data.code)
                      return false


                    this.vm = res.data.data
                    this.connect()
                    // this.$message({
                    //   message: '正在连接中...',
                    //   type: 'success'
                    // })
                  }
              )

            }
          }
      )
    },
    init() {
      this.infoIcon = true
      this.infoText = "正在连接中..."

      this.getPort()

      this.autoTimer = setInterval(() => {
        this.getPort()
      }, 5000)

    }
  },
  mounted() {

    const vm = this
    this.$nextTick(() => {
      vm.init()
    })
  }
}
</script>

<style scoped>

.header {
}

.display {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.viewport {
  position: relative;
  width: 100%;
  height: 100%;
}

.logo {
  z-index: 999;
  height: 80px;
  position: absolute;
  right: 80px;
  top: 40px;

}
</style>

<style>
html, body {
  margin: 0;
  height: 100%;
  width: 100%;
}

.container {
  width: 100%;
  height: 100%;
}

#app {
  height: 100%;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 16pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 1rem;
}

h1 {
  text-align: center;
}

.field {
  display: grid;
  grid-template-columns: 300px 1fr;
  margin-bottom: 1rem;
}

label {
  text-align: right;
}

label::after {
  content: ': '
}

input {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 16pt;
  border: 1px solid black;
  border-radius: 2px;
  padding-left: 0.5rem;
}

.center {
  text-align: center;
}

.connect {
  font-size: 16pt;
  background: none;
  border: 1px solid black;
  border-radius: 5px;
  padding: .5rem 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}


.pop-up {
  position: fixed;
  left: 0px;
  top: 0px;
  background: #000;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px
  }
  to {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px
  }
}


.pop-up .msg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.pop-up h2 {
  padding: 0;
  margin-left: 30px;
  font-size: 35px;
  letter-spacing: 10px;
}

.pop-up .circular {
  width: 50px;
  height: 50px;
}

.pop-up .path {
  animation: loading-dash 4s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 5;
  stroke: #fff;
  stroke-linecap: round;
}


</style>
