<template>
  <Layout>
    <div class="container mt-5">
      <div class="course">
        <div class="mb-5">
          <ul class="course-ul">
            <li>
              <a href="/">首页</a>
              <i class="uil uil-angle-right-b"></i>
            </li>
            <li>
              <router-link
                  :to="{ path: '/courses/details',query: { id: $route.query.cid} }">
                {{ info?.name }}
              </router-link>
            </li>
          </ul>
        </div>
        <h4>{{ chapter?.name }}</h4>
      </div>
      <div v-if="chapter.type === 2">

        <div class="operation">

          <button ref="start" :disabled="startDisabled" class="source" @click="applyTopology">
            <svg v-if="!startDisabled" class="icon" height="55" p-id="1726" t="1653662873930"
                 version="1.1"
                 viewBox="0 0 1024 1024" width="55" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M512 989.4c-123.1 0-238.9-47.9-326-135S51 651.5 51 528.4s47.9-238.9 135-326c17-17 44.6-17 61.6 0s17 44.6 0 61.6C177 334.7 138.1 428.6 138.1 528.4c0 99.9 38.9 193.8 109.5 264.4 70.6 70.6 164.5 109.5 264.4 109.5s193.8-38.9 264.4-109.5c70.6-70.6 109.5-164.5 109.5-264.4 0-99.9-38.9-193.8-109.5-264.4-17-17-17-44.6 0-61.6s44.6-17 61.6 0c87.1 87.1 135 202.8 135 326 0 123.1-47.9 238.9-135 326s-202.9 135-326 135z"
                  fill="#00af92" p-id="1727"></path>
              <path
                  d="M512 554.7c-23.6 0-42.7-19.1-42.7-42.7V85.3c0-23.6 19.1-42.7 42.7-42.7s42.7 19.1 42.7 42.7V512c0 23.6-19.1 42.7-42.7 42.7z"
                  fill="#00af92" p-id="1728"></path>
            </svg>

            <svg v-if="startDisabled" class="icon" height="55" p-id="2343" t="1653711193199"
                 version="1.1"
                 viewBox="0 0 1024 1024" width="55" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M512 989.4c-123.1 0-238.9-47.9-326-135S51 651.5 51 528.4s47.9-238.9 135-326c17-17 44.6-17 61.6 0s17 44.6 0 61.6C177 334.7 138.1 428.6 138.1 528.4c0 99.9 38.9 193.8 109.5 264.4 70.6 70.6 164.5 109.5 264.4 109.5s193.8-38.9 264.4-109.5c70.6-70.6 109.5-164.5 109.5-264.4 0-99.9-38.9-193.8-109.5-264.4-17-17-17-44.6 0-61.6s44.6-17 61.6 0c87.1 87.1 135 202.8 135 326 0 123.1-47.9 238.9-135 326s-202.9 135-326 135z"
                  fill="#cdcdcd" p-id="2344"></path>
              <path
                  d="M512 554.7c-23.6 0-42.7-19.1-42.7-42.7V85.3c0-23.6 19.1-42.7 42.7-42.7s42.7 19.1 42.7 42.7V512c0 23.6-19.1 42.7-42.7 42.7z"
                  fill="#cdcdcd" p-id="2345"></path>
            </svg>
            <span>开机</span>
          </button>

          <button :disabled="closeDisabled" class="source" @click="destroyTopology">
            <svg v-if="!closeDisabled" class="icon" height="55" p-id="1726" t="1653662873930"
                 version="1.1"
                 viewBox="0 0 1024 1024" width="55" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M512 989.4c-123.1 0-238.9-47.9-326-135S51 651.5 51 528.4s47.9-238.9 135-326c17-17 44.6-17 61.6 0s17 44.6 0 61.6C177 334.7 138.1 428.6 138.1 528.4c0 99.9 38.9 193.8 109.5 264.4 70.6 70.6 164.5 109.5 264.4 109.5s193.8-38.9 264.4-109.5c70.6-70.6 109.5-164.5 109.5-264.4 0-99.9-38.9-193.8-109.5-264.4-17-17-17-44.6 0-61.6s44.6-17 61.6 0c87.1 87.1 135 202.8 135 326 0 123.1-47.9 238.9-135 326s-202.9 135-326 135z"
                  fill="#d81e06" p-id="1727"></path>
              <path
                  d="M512 554.7c-23.6 0-42.7-19.1-42.7-42.7V85.3c0-23.6 19.1-42.7 42.7-42.7s42.7 19.1 42.7 42.7V512c0 23.6-19.1 42.7-42.7 42.7z"
                  fill="#d81e06" p-id="1728"></path>
            </svg>

            <svg v-if="closeDisabled" :disabled="closeDisabled" class="icon" height="55" p-id="2343" t="1653711193199"
                 version="1.1"
                 viewBox="0 0 1024 1024" width="55" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M512 989.4c-123.1 0-238.9-47.9-326-135S51 651.5 51 528.4s47.9-238.9 135-326c17-17 44.6-17 61.6 0s17 44.6 0 61.6C177 334.7 138.1 428.6 138.1 528.4c0 99.9 38.9 193.8 109.5 264.4 70.6 70.6 164.5 109.5 264.4 109.5s193.8-38.9 264.4-109.5c70.6-70.6 109.5-164.5 109.5-264.4 0-99.9-38.9-193.8-109.5-264.4-17-17-17-44.6 0-61.6s44.6-17 61.6 0c87.1 87.1 135 202.8 135 326 0 123.1-47.9 238.9-135 326s-202.9 135-326 135z"
                  fill="#cdcdcd" p-id="2344"></path>
              <path
                  d="M512 554.7c-23.6 0-42.7-19.1-42.7-42.7V85.3c0-23.6 19.1-42.7 42.7-42.7s42.7 19.1 42.7 42.7V512c0 23.6-19.1 42.7-42.7 42.7z"
                  fill="#cdcdcd" p-id="2345"></path>
            </svg>
            <span>关机</span>
          </button>

          <button v-if="this.envTask.status === 1001" class="source" @click="delayTopology">
            <svg class="icon" height="55" p-id="2723" t="1654262564789" version="1.1"
                 viewBox="0 0 1024 1024" width="55" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M483.2256 953.4464a441.6512 441.6512 0 1 1 244.9408-808.96l-45.4656 68.096A359.7312 359.7312 0 1 0 842.9568 512h81.92a442.1632 442.1632 0 0 1-441.6512 441.4464z"
                  fill="#EA9920" p-id="2724"></path>
              <path
                  d="M778.5472 519.3728l99.5328-145.8176 104.7552 145.8176H778.5472zM675.84 583.4752H432.0256V302.6944h81.92v198.8608H675.84v81.92z"
                  fill="#EA9920" p-id="2725"></path>
            </svg>
            <span>延长时间</span>
          </button>

          <button v-if="this.envTask.status === 1001 && topo.vpn" class="source" :disabled="vpnDisabled"
                  @click="vpnFile">
            <svg v-if="!vpnDisabled" t="1735123241186" class="icon" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="18010" width="55" height="55">
              <path
                  d="M512 1024a512 512 0 1 1 512-512 512 512 0 0 1-512 512z m0-960a448 448 0 1 0 448 448A448 448 0 0 0 512 64z"
                  fill="#487EE7" p-id="18011"></path>
              <path d="M480 64h64v896h-64z" fill="#487EE7" p-id="18012"></path>
              <path
                  d="M512 1024c-143.68 0-256-224-256-512s112.32-512 256-512 256 224 256 512-112.32 512-256 512z m0-960c-90.88 0-192 184-192 448s101.12 448 192 448 192-184 192-448-101.12-448-192-448z"
                  fill="#487EE7" p-id="18013"></path>
              <path d="M32 480h960v64H32zM96 256h832v64H96zM96 704h832v64H96z" fill="#487EE7" p-id="18014"></path>
            </svg>

            <svg v-if="vpnDisabled" viewBox="25 25 50 50" class="circular">
              <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
            </svg>
            <span>VPN连接</span>
          </button>

          <router-link v-if="chapter.document !== undefined && chapter.document.length !== 0"
                       :to="{path:'/courses/pdf',query:{id:chapter.document}}"
                       class="source" target='_blank'>
            <svg class="icon" height="55" p-id="20567" t="1653666139663" version="1.1"
                 viewBox="0 0 1024 1024" width="55" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M160 32c-12.0064 0-24.8064 4.8-33.6 14.4-8.8064 9.6-14.4 21.6064-14.4 33.6v864c0 12.0064 4.8 24.8064 14.4 33.6 9.6 9.6 21.6064 14.4 33.6 14.4h704c12.0064 0 24.8064-4.8 33.6-14.4 9.6-9.6 14.4-21.6064 14.4-33.6v-640L640 32H160z"
                  fill="#FF5562" p-id="20568"></path>
              <path d="M912 304h-224c-12.0064 0-24.8064-4.8-33.6-14.4C644.8 280.7936 640 267.9936 640 256V32l272 272z"
                    fill="#FFBBC0" p-id="20569"></path>
              <path
                  d="M696 843.2c-50.4064 0-95.2064-86.4-119.2064-142.4-39.9872-16.8064-83.9936-32-126.3872-42.4064-37.6064 24.8064-100.8128 61.6064-149.6064 61.6064-30.4 0-52.0064-15.2064-60.0064-41.6-6.4-21.6064-0.7936-36.8 5.6064-44.8 12.8-17.6 39.2064-26.4064 79.2064-26.4064 32 0 72.7936 5.6064 118.4 16.8064 29.5936-20.8 59.1872-44.8 85.5936-70.4-12.0064-56-24.8064-146.4064 8-188.0064 16-19.9936 40.8064-26.3936 70.4-17.6 32.8064 9.6 44.8 29.6064 48.8064 44.8 13.5936 54.4-48.8064 128-91.2128 171.2 9.6 37.6064 21.6064 76.8 36.8128 112.8064 60.8 27.2 132.7872 67.2 140.8 111.2064 3.2 15.1936-1.6128 29.5936-13.6064 41.6-10.4064 8.7936-21.6064 13.5936-33.6 13.5936z m-74.4064-121.6c30.4128 61.6064 59.2128 90.4064 74.4064 90.4064 2.4064 0 5.6064-0.8064 10.4064-4.8128 5.5936-5.5936 5.5936-9.6 4.7872-12.8-3.2-16-28.8-42.3936-89.6-72.7936z m-295.9872-82.4064c-39.2064 0-50.4064 9.6-53.6064 13.6064-0.8064 1.6-4.0064 5.6064-0.8064 16.8064 2.4064 9.6 8.8064 19.2 29.6064 19.2 25.6 0 62.4-14.4128 105.6-40.0128-31.2064-6.4-58.4064-9.6-80.8064-9.6z m158.4-4.7872c25.6 7.1936 51.9936 16 76.8 25.6-8.8064-23.2064-16-47.2064-22.4-70.4a1470.2848 1470.2848 0 0 1-54.4 44.8z m99.2-258.4064c-8.8064 0-15.2064 3.2-20.8128 9.6-16.7936 20.8-18.3936 73.6-5.5936 140.8 48.8064-52.0064 75.2-100.0064 68.8-125.6064-0.8064-3.9936-4.0064-15.1936-26.4064-21.5936-6.4-2.4064-11.1872-3.2-16-3.2z"
                  fill="#FFFFFF" p-id="20570"></path>
            </svg>
            <span>实验手册</span>
          </router-link>


        </div>

        <div class="mt-4 mb-4" v-show="(envTask.status === 1002)">
          <b-progress animated height="25px">
            <b-progress-bar :value="envTask.rate" max="100"
            >环境生成中（ {{ envTask.rate }}% ）
            </b-progress-bar>
          </b-progress>
        </div>

        <div class="mt-5 mb-5" v-show="(envTask.status === 1001)">
          <div style="display:inline-block">
            <p class="downtime mb-1">目标IP地址</p>
            <p class="target"> 172.16.12.100</p>
          </div>

          <div class=" ms-5" v-if="this.downTime.length !== 0" style="display:inline-block">
            <p class="downtime mb-1">剩余时间</p>
            <p class="target" style="color: #000;"> {{ downTime }}</p>
          </div>


        </div>

        <div class="vm_list">
          <div v-for="(item,index) in vm" :key="index" class="single_vm">
            <div class="vm_icon">
              <svg class="icon" height="80" p-id="2295" t="1653655843266"
                   version="1.1"
                   viewBox="0 0 1024 1024" width="80" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M122.88 287.232l389.632-224.768 46.592 27.136 26.624-15.36L512 31.744 96.256 271.872v331.776H122.88zM512 961.536l-389.12-224.768v-51.2h-26.624v66.56l415.744 240.128 283.136-162.816-26.624-15.872zM651.264 112.64l-26.624 15.36 276.48 159.232v449.536l-66.048 37.888 26.624 15.872 66.048-37.888V272.384z"
                    fill="#2B85FB" p-id="2296"></path>
                <path
                    d="M512 515.584c0-0.512-124.928-72.192-124.928-72.192l124.416 71.168 304.64-174.592-124.928-71.68-3.584-2.56-304.128 175.616V527.36l3.584 2.048 124.928 71.68v-0.512l304.64-174.592V339.968L512 514.56v1.024z"
                    fill="#2B85FB" p-id="2297"></path>
                <path
                    d="M387.072 576.512l-55.296-31.744-124.928-71.68v85.504l124.928 71.68 55.296 31.744 124.928 71.68 304.64-174.592V473.6L512 648.192z"
                    fill="#2B85FB" p-id="2298"></path>
                <path
                    d="M387.072 710.144l-179.712-103.424v85.504l179.712 103.424 124.928 71.68 304.64-175.616v-86.016L512 781.312c0 0.512-124.928-71.168-124.928-71.168zM207.872 339.968h-0.512v85.504l124.928 71.68 3.584 2.048V413.696L640 238.08l-3.584-2.048-124.416-71.68z"
                    fill="#2B85FB" p-id="2299"></path>
              </svg>
            </div>
            <div class="vm_info">
              <p>{{ item.vm_name }}</p>
              <router-link :to="{path:'/courses/vnc',query:{id:item.id}}" class="m-2" target='_blank'>
                <i class="uil uil-desktop" style="margin-right: 5px"></i>
                OPEN VNC
              </router-link>

              <router-link v-if="item.vm_rdp" :to="{path:'/courses/rdp',query:{id:item.id}}" class="m-2"
                           target='_blank'>
                <i class="uil uil-desktop" style="margin-right: 5px"></i>
                OPEN RDP
              </router-link>

            </div>

          </div>
        </div>

        <!--        <button class="btn btn-danger m-2" @click="destroyTopology" v-if="vm.length !== 0">-->
        <!--          <i class="uil uil-times-circle"></i> Destroy environment-->
        <!--        </button>-->
        <!--        <div>-->
        <!--          <router-link :to="{path:'/courses/rdp'}" class="btn btn-primary m-2" target='_blank'>-->
        <!--            <i class="uil uil-desktop" style="margin-right: 5px"></i>-->
        <!--            攻击机 (流畅版）-->
        <!--          </router-link>-->
        <!--        </div>-->
      </div>

      <div class="mt-5" v-html="chapter.content"></div>


    </div>
  </Layout>
</template>

<script>

import Layout from '@layouts/main'
import moment from 'moment'

export default {
  components: {Layout},
  data() {
    return {
      chapter: [],
      vm: [],
      topo: [],
      info: null,
      startDisabled: true,
      closeDisabled: true,
      vpnDisabled: true,
      downTime: '',
      envTask: [],
      timer: null,
      timerEnv: null,
      checkVpnTimer: null
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {

      this.chapter = [];
      this.vm = [];
      this.topo = [];
      this.info = null;
      this.startDisabled = true;
      this.closeDisabled = true;
      this.downTime = '';
      this.envTask = [];
      this.timer = null;
      this.timerEnv = null;

      const vm = this
      this.$axios.post('/Course/GetChapter?cid=' + this.$route.query.cid + '&ccid=' + this.$route.query.ccid).then((res) => {
        vm.chapter = res.data.data

        vm.startcheckEnv()

        vm.getTopo()

        vm.topoInfo()

      })

      this.$axios.get('/Course/GetCourse', {params: {id: this.$route.query.cid}}).then((res) => {
        vm.info = res.data.data
      })

    },
    applyTopology() {
      const vm = this
      this.startDisabled = true
      this.$axios.post('/VM/UserStartTopologyDrag', {
        topologyid: this.chapter.env_id,
        cid: this.$route.query.cid,
        ccid: this.$route.query.ccid
      }).then((res) => {
        vm.vm = res.data.data
        this.$message({
          showClose: true,
          message: res.data.msg,
          type: res.data.code === 1001 ? 'success' : 'warning'
        })

        vm.startcheckEnv()
      })
    },
    destroyTopology() {
      const vm = this
      this.closeDisabled = true

      this.$message({
        showClose: true,
        message: "环境开始销毁中",
        type: 'warning'
      })


      this.$axios.post('/VM/UserDestroyTopologyDrag', {
        topologyid: this.chapter.env_id,
        cid: this.$route.query.cid,
        ccid: this.$route.query.ccid
      }).then((res) => {
        vm.vm = res.data.data
        this.$message({
          showClose: true,
          message: res.data.msg,
          type: res.data.code === 1001 ? 'success' : 'warning'
        })
        this.init()
      })
    },
    delayTopology() {
      this.$axios.post('/VM/DelayTopology?topologyid=' + this.chapter.env_id).then((res) => {
        this.$message({
          message: res.data.msg,
          type: res.data.code === 1001 ? 'success' : 'warning'
        })
        if (res.data.code === 1001) {
          this.init()
        }
      })
    },
    topoInfo() {
      const vm = this
      this.closeDisabled = true
      this.$axios.get('/VM/TopoInfo', {
        params: {
          id: this.chapter.env_id,
        }
      }).then((res) => {
        vm.topo = res.data.data

        if (vm.topo.vpn) {
          // 检查VPN是否准备好

          this.checkVPN()

          this.checkVpnTimer = setInterval(() => {
            this.checkVPN()
          }, 3000)

        }

      })
    },
    countDown() {
      if (this.envTask.status !== 1001) {
        this.downTime = '';
        return -1;
      }

      if (this.vm.length === 0) {
        this.downTime = '';
        return -1;
      }

      let createTime = new Date(this.vm[0]['createTime']);

      createTime.setMinutes(createTime.getMinutes() + this.vm[0]['usetime'])

      var nowDate = new Date()

      let dura = moment(createTime).format('x') - moment(nowDate).format('x')

      let tempTime = moment.duration(dura);
      if (tempTime.asSeconds() <= 0) {
        this.downTime = '环境已' +
            '，将自动销毁'
        this.vm = []
        this.startDisabled = false
        this.closeDisabled = true
      } else {
        if (tempTime.hours() === 0) {
          this.downTime = tempTime.minutes() + "分" + tempTime.seconds() + "秒"

        } else if (tempTime.hours() === 0 && tempTime.minutes() === 0) {
          this.downTime = tempTime.seconds() + "秒"
        } else {
          this.downTime = tempTime.hours() + "小时" + tempTime.minutes() + "分" + tempTime.seconds() + "秒"
        }
      }
    },
    startcheckEnv() {
      if (this.timerEnv !== null) {
        return;
      }

      this.checkEnv()

      this.timerEnv = setInterval(() => {
        this.checkEnv()
      }, 1000)
    }
    ,
    async checkEnv() {
      this.$axios.get('/VM/GetTopoTask').then((res) => {


        if (res.data.data === null) {
          this.envTask = []
          clearInterval(this.timerEnv)
          this.timerEnv = null

        } else {
          this.envTask = res.data.data

          if (res.data.data.tid !== this.chapter.env_id) {
            this.envTask = []
            clearInterval(this.timerEnv)
            this.timerEnv = null
          }

          this.startDisabled = true

          if (res.data.data.status === 1001) {
            this.getTopo()
            clearInterval(this.timerEnv)
            this.timerEnv = null
          }
        }
      })
    },
    getTopo() {

      var vm = this
      vm.startDisabled = true
      vm.closeDisabled = true

      vm.$axios.post('/VM/GetUserTopology?envType=' + this.chapter.env_type +
          '&topologyid=' + this.chapter.env_id,
      ).then((res) => {
        vm.vm = res.data.data

        if (this.envTask.status !== 1001) {
          this.startDisabled = false
        } else {
          this.closeDisabled = false
        }

        this.downTime = ''

        this.timer = setInterval(() => {
          let result = this.countDown()
          if (result == -1) {
            clearInterval(this.timer)
          }
        }, 1000)

      })
    },
    checkVPN() {

      this.vpnDisabled = true

      this.$axios.get('/VM/GetVPNFile', {
        params: {
          tid: this.chapter.env_id
        }
      }).then((res) => {
        this.vpnDisabled = true

        if (res.data.code == 1001) {
          this.vpnDisabled = false
          clearInterval(this.checkVpnTimer)
          this.checkVpnTimer = null
        }

      })


    },
    vpnFile() {
      this.$axios.get('/VM/GetVPNFile', {
        params: {
          tid: this.chapter.env_id
        }
      }).then((res) => {

        if (res.data.code == 2001) {

          this.$message({
            showClose: true,
            message: res.data.msg,
            type: res.data.code === 1001 ? 'success' : 'warning'
          })

          return
        }

        try {
          // 解码 Base64 数据
          const binaryString = atob(res.data.data);
          const binaryLength = binaryString.length;
          const bytes = new Uint8Array(binaryLength);

          // 将解码后的字符串转为字节数组
          for (let i = 0; i < binaryLength; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          // 创建 Blob 对象
          const blob = new Blob([bytes], {type: "application/octet-stream"});

          // 创建下载链接
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "oneorg_lab.ovpn";
          link.click();

          // 清理 URL 对象
          URL.revokeObjectURL(link.href);

          // 清除错误信息
          this.errorMessage = "";
        } catch (error) {
          // 处理错误
          console.error(error);
        }
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timerEnv);
    this.timer = null;
    this.timerEnv = null
  }

}
</script>

<style scoped>

.operation {
  background-color: #ebf0f6;
  padding: 15px 20px;
  border-radius: 20px;
}

.operation .source {
  background: none;
  border: none;
  font-size: 22px;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  margin-right: 60px;
  margin-left: 60px;
}

.operation a:visited {
  color: #000;
  text-decoration: none;
}

.operation .source span {
  margin-top: 10px;
}

.course h4 {
  font-size: 30px;
  line-height: 44px;
  color: #2c234d;
  position: relative;
  top: -11px;
  margin: 0 0 18px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ebecf2;
}

.btn-pdf {
  background-color: #009695;
  color: white;
}

.vm_list {

}

.single_vm {
  display: inline-flex;
  align-items: center;
  margin-right: 100px;
  margin-bottom: 30px;
  border-left: 6px solid #2b85fb;
}

.single_vm .vm_icon {
  padding-left: 20px;
  padding-right: 20px;
}

.single_vm .vm_info {
  display: inline;
  margin-left: 15px;
  min-height: 88px;
}

.single_vm .vm_info p {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.single_vm .vm_info a {
  padding: 0;
  margin: 6px 0 0 0 !important;
  text-decoration: none;
  display: block;
}

.downtime {
  font-size: 1rem;
  margin: 0;
}

.course-ul {
  margin: 0 0 12px;
  padding: 0;
  list-style: none;
}

.course-ul li {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.course-ul li a {
  font-size: 16px;
}

.course-ul li i {
  font-size: 16px;
  margin: 10px;
}

.target {
  font-size: 26px;
  font-weight: bold;
  color: #4883F8;
  margin-bottom: 0;
}

.circular {
  width: 55px;
  height: 55px;
  animation: loading-dash 4s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 5;
  stroke: #4883F8;
  stroke-linecap: round;
  display: inline-block;
}
</style>
